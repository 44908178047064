import { colors } from "vuetify/lib";

const state = {
  orderStatus: [
    {
      text: "Cancelled",
      value: "cancelled",
      color: colors.red.base,
      report: true,
      delivered: false,
      sort: -3,
    },
    {
      text: "Rejected",
      value: "rejected",
      color: colors.red.base,
      report: true,
      delivered: false,
      sort: -2,
    },
    {
      text: "Awaiting Approval",
      value: "draft",
      color: colors.grey.base,
      report: true,
      delivered: false,
      sort: -1,
    },
    {
      text: "Submitted",
      value: "submitted",
      color: colors.shades.black,
      report: true,
      delivered: false,
      sort: 0,
    },
    {
      text: "Accepted",
      value: "accepted",
      color: colors.blue.base,
      report: true,
      delivered: false,
      sort: 1,
    },
    {
      text: "Delivery Confirmation",
      value: "deliveryConfirmation",
      color: colors.blue.darken3,
      report: true,
      delivered: false,
      sort: 2,
    },
    {
      text: "Fulfilled",
      value: "fulfilled",
      color: colors.brown.base,
      report: true,
      delivered: false,
      sort: 3,
    },
    {
      text: "Shipped",
      value: "shipped",
      color: colors.purple.base,
      report: true,
      delivered: true,
      sort: 4,
      ar: true,
    },
    {
      text: "Complete",
      value: "complete",
      color: colors.green.base,
      report: true,
      delivered: true,
      sort: 5,
      ar: true,
    },
  ],
  paymentTypes: [
    {
      text: "Sample Order - Clear Balance",
      value: "sample-clearBalance",
      salesOrders: true,
      general: false,
      recordTypes: ["salesOrders"],
    },
    {
      text: "Promo Items Only - Clear Balance",
      value: "promo-clearBalance",
      salesOrders: true,
      general: false,
      recordTypes: ["salesOrders"],
    },
    {
      text: "Cash",
      value: "cash",
      salesOrders: true,
      general: true,
      recordTypes: [
        "salesOrders",
        "vendorOrders",
        "customOrders",
        "rawMaterialOrders",
        "whiteLabelOrders",
      ],
    },
    {
      text: "Check",
      value: "check",
      salesOrders: true,
      general: true,
      recordTypes: [
        "salesOrders",
        "vendorOrders",
        "customOrders",
        "rawMaterialOrders",
        "whiteLabelOrders",
      ],
    },
    {
      text: "ACH",
      value: "ach",
      salesOrders: true,
      general: true,
      recordTypes: [
        "salesOrders",
        "vendorOrders",
        "customOrders",
        "rawMaterialOrders",
        "whiteLabelOrders",
      ],
    },
    {
      text: "Credit Card",
      value: "creditCard",
      salesOrders: true,
      general: true,
      recordTypes: [
        "salesOrders",
        "vendorOrders",
        "customOrders",
        "rawMaterialOrders",
        "whiteLabelOrders",
      ],
    },
    {
      text: "Overpayment Credit",
      value: "credit",
      salesOrders: true,
      general: false,
      recordTypes: [],
    },
    {
      text: "Trade",
      value: "trade",
      salesOrders: true,
      general: true,
      recordTypes: [
        "salesOrders",
        "vendorOrders",
        "customOrders",
        "rawMaterialOrders",
        "whiteLabelOrders",
      ],
    },
    {
      text: "Wire",
      value: "wire",
      salesOrders: true,
      general: true,
      recordTypes: [
        "salesOrders",
        "vendorOrders",
        "customOrders",
        "rawMaterialOrders",
        "whiteLabelOrders",
      ],
    },
    {
      text: "Cashier",
      value: "cashier",
      salesOrders: true,
      general: true,
      recordTypes: [
        "salesOrders",
        "vendorOrders",
        "customOrders",
        "rawMaterialOrders",
        "whiteLabelOrders",
      ],
    },
    {
      text: "Other",
      value: "other",
      salesOrders: true,
      general: true,
      recordTypes: [
        "salesOrders",
        "vendorOrders",
        "customOrders",
        "rawMaterialOrders",
        "whiteLabelOrders",
      ],
    },
    {
      text: "Credits",
      value: "promotionCredit",
      salesOrders: true,
      general: false,
      recordTypes: ["salesOrders"],
    },
    {
      text: "Charge Off",
      value: "chargeOff",
      salesOrders: true,
      general: false,
      recordTypes: ["salesOrders"],
    },
    {
      text: "Vendor Credit",
      value: "vendorCredit",
      salesOrders: false,
      general: false,
      recordTypes: ["vendorOrders", "customOrders", "rawMaterialOrders"],
    },
  ],
  creditTypes: [
    {
      text: "Overpayment",
      value: "overpayment",
      icon: "mdi-cash-refund",
      salesOrders: true,
      general: true,
    },
    {
      text: "Promotion",
      value: "promotion",
      icon: "mdi-seal-variant",
      salesOrders: true,
      general: true,
    },
    {
      text: "Defect",
      value: "defect",
      icon: "mdi-bandage",
      salesOrders: true,
      general: true,
    },
    {
      text: "Shelf",
      value: "shelf",
      icon: "mdi-bookshelf",
      salesOrders: true,
      general: true,
    },
    {
      text: "Event/Display Sponsorship",
      value: "event",
      icon: "mdi-calendar",
      salesOrders: true,
      general: true,
    },
    {
      text: "Credit Memo",
      value: "creditMemo",
      icon: "mdi-file-certificate",
      salesOrders: true,
      general: true,
    },
    {
      text: "Special Pricing",
      value: "specialPricing",
      icon: "mdi-sale",
      salesOrders: true,
      general: false,
    },
    {
      text: "Other",
      value: "other",
      icon: "mdi-dots-horizontal-circle",
      salesOrders: true,
      general: true,
    },
  ],
  paymentTerms: [
    { text: "COD", value: "cod", color: "green", due: 0, leaflink: 20 },
    { text: "Net 7", value: "net 7", color: "black", due: 7, leaflink: 2 },
    { text: "Net 10", value: "net 10", color: "black", due: 10, leaflink: 5 },
    { text: "Net 14", value: "net 14", color: "orange", due: 14, leaflink: 9 },
    { text: "Net 15", value: "net 15", color: "orange", due: 15, leaflink: 9 },
    { text: "Net 30", value: "net 30", color: "brown", due: 30, leaflink: 4 },
    { text: "Net 45", value: "net 45", color: "brown", due: 45, leaflink: 22 },
    { text: "Net 60", value: "net 60", color: "brown", due: 60, leaflink: 7 },
    { text: "Net 90", value: "net 90", color: "brown", due: 90, leaflink: 14 },
    {
      text: "Consignment",
      value: "consignment",
      color: "brown",
      due: 30,
    },
    { text: "[Not Set]", value: null, color: "black", due: -1 },
  ],
  paymentPurposes: [
    { text: "Excise Tax", value: "excise" },
    { text: "Subtotal", value: "subTotal" },
    { text: "Split", value: "split" },
  ],
  deliveryTypes: [
    {
      text: "Delivery/Logistic company",
      desc: "Select delivery company",
      value: "deliveryLogistics",
    },
    {
      text: "Internal",
      desc: "Select a user within Mytrace to deliver",
      value: "internal",
    },
    {
      text: "Store Pick-up",
      desc: "The store will pick up this order",
      value: "account",
    },
  ],
  sampleOrderPurpose: [
    {
      text: "Buyer Samples",
      desc: "Samples intended to familiarize buyers with the product.",
      value: "buyerSamples",
    },
    {
      text: "Staff Samples",
      desc: "Samples provided to introduce staff members to the product.",
      value: "staffSamples",
    },
    {
      text: "Event Samples",
      desc: "Samples prepared for use at an upcoming event.",
      value: "eventSamples",
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getOrderStatus(state) {
    return state.orderStatus;
  },
  getPaymentTerms(state) {
    return state.paymentTerms;
  },
  getPaymentTypes(state) {
    return state.paymentTypes;
  },
  getCreditTypes(state) {
    return state.creditTypes;
  },
  getPaymentPurposes(state) {
    return state.paymentPurposes;
  },
  getDeliveryTypes(state) {
    return state.deliveryTypes;
  },
  getSampleOrderPurpose(state) {
    return state.sampleOrderPurpose;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
