import colors from "vuetify/lib/util/colors";

const state = {
  activityDialog: false,
  accountStatus: [
    {
      text: "Cold Lead",
      value: "lead_cold",
      icon: "mdi-forum",
      active: false,
      create: true,
      canOrder: true,
      canCreateStoreEvents: false,
      managerApproval: false,
      desc: "No prior contact, individual who could approve a sample order has not been identified.",
      accountType: 2,
      color: colors.grey.base,
    },
    {
      text: "Hot Lead",
      value: "lead_hot",
      icon: "mdi-forum",
      active: false,
      create: true,
      canOrder: true,
      canCreateStoreEvents: false,
      managerApproval: false,
      desc: "A contact with the ability to approve a sample order has been made. These accounts should take higher priority > cold leads.",
      accountType: 2,
      color: colors.orange.base,
    },
    {
      text: "On Hold - Lead",
      value: "lead_onHold",
      icon: "mdi-forum",
      active: true,
      canOrder: false,
      canCreateStoreEvents: false,
      desc: "This lead is on hold due to management changes/license issues/etc. The status will need to be updated back to a cold/hot lead to create orders.",
      accountType: 2,
      color: colors.red.darken3,
    },
    {
      text: "Samples - Sent",
      value: "samples_sent",
      icon: "mdi-folder-question-outline",
      create: true,
      active: false,
      canOrder: true,
      canCreateStoreEvents: false,
      desc: `A *Lead status account has had a sample order submitted. Once delivered the status will auto-update to "Samples - Received".`,
      managerApproval: false,
      accountType: 2,
      color: colors.yellow.darken1,
    },
    {
      text: "Samples - Received",
      value: "samples_received",
      icon: "mdi-folder-question-outline",
      create: true,
      active: false,
      canOrder: true,
      canCreateStoreEvents: false,
      desc: `This account has received their sample order. A new task 2+ weeks in the future has been assigned to the rep managing the account to follow up.`,
      managerApproval: false,
      accountType: 2,
      color: colors.lightBlue.base,
    },
    {
      text: "Inactive - Needs Reonboarding",
      value: "needs_reonboarding",
      icon: "mdi-folder-account",
      active: true,
      create: true,
      canOrder: true,
      canCreateStoreEvents: true,
      desc: "Account was previously active, has not placed orders in 6+ months & needs to be reviewed.",
      managerApproval: false,
      accountType: 2,
      color: colors.deepPurple.darken3,
    },
    {
      text: "Active - Current payments",
      value: "active_current",
      icon: "mdi-folder-account",
      active: true,
      create: true,
      canOrder: true,
      canCreateStoreEvents: true,
      desc: "Account has been converted from a *Lead to an active account. Payments are on time & accounts receivable is minimal.",
      managerApproval: false,
      accountType: 2,
      color: colors.green.base,
    },
    {
      text: "Active - Orders Under Hub Account",
      value: "active_ordersUnderHubAccount",
      icon: "mdi-folder-account",
      active: true,
      create: true,
      canOrder: false,
      canCreateStoreEvents: true,
      desc: "This account is active, but orders are submitted on a different 'Hub' style Account. Orders should not be placed on this account.",
      managerApproval: false,
      accountType: 2,
      color: colors.green.accent3,
    },
    {
      text: "Active - Delinquent payments",
      value: "active_delinquent",
      icon: "mdi-folder-account",
      active: true,
      canOrder: true,
      canCreateStoreEvents: true,
      desc: "Account is actively ordering, but has 1+ open invoice(s) outside of its terms.",
      managerApproval: true,
      accountType: 2,
      color: colors.red.lighten3,
    },
    {
      text: "Payment Plan - Actively Ordering",
      value: "paymentPlan_active",
      icon: "mdi-folder-account",
      active: true,
      canOrder: true,
      canCreateStoreEvents: true,
      managerApproval: true,
      desc: "Account is on a payment plan & actively ordering.",
      accountType: 2,
      color: colors.purple.base,
    },
    {
      text: "Payment Plan - No Orders",
      value: "paymentPlan_inactive",
      icon: "mdi-hand-coin",
      active: true,
      canOrder: false,
      canCreateStoreEvents: false,
      desc: "Account is on a payment plan & cannot place any new orders.",
      managerApproval: true,
      accountType: 3,
      color: colors.yellow.base,
    },
    {
      text: "Credit Hold",
      value: "credit_hold",
      icon: "mdi-hand-coin",
      active: true,
      canOrder: false,
      canCreateStoreEvents: false,
      desc: "Account is not on a payment plan & cannot place any new orders.",
      managerApproval: true,
      accountType: 3,
      color: colors.blueGrey.base,
    },
    {
      text: "Out of business - Client",
      value: "oob_client",
      icon: "mdi-storefront-remove",
      active: false,
      canOrder: false,
      canCreateStoreEvents: false,
      desc: "Account has closed doors, and is no longer in business. Previously was an account.",
      accountType: 3,
      color: colors.shades.black,
    },
    {
      text: "Out of business - Not a client",
      value: "oob_notclient",
      icon: "mdi-storefront-remove",
      active: false,
      canOrder: false,
      canCreateStoreEvents: false,
      desc: "Account has closed doors and was never converted to being an account.",
      accountType: 3,
      color: colors.shades.black,
    },
    {
      text: "DO NOT CALL",
      value: "do_not_call",
      icon: "mdi-skull",
      active: false,
      canOrder: false,
      canCreateStoreEvents: false,
      desc: "This account has requested or we have identified not to do business with them due to unpaid balances/etc.",
      accountType: 3,
      color: colors.red.base,
    },
  ],
  subStatus: [
    {
      text: "Collections - In House",
      value: "collectionsInHouse",
      status: ["active_delinquent", "credit_hold"],
      color: colors.amber.darken4,
    },
    {
      text: "Collections - 3rd Party",
      value: "collections3rdParty",
      status: ["active_delinquent", "credit_hold"],
      color: colors.red.darken4,
    },
    {
      text: "3rd Party - Discharge",
      value: "thirdPartyDischarge",
      status: ["active_delinquent", "credit_hold"],
      color: colors.brown.base,
    },
    {
      text: "Litigation",
      value: "litigation",
      status: ["active_delinquent", "credit_hold"],
      color: colors.deepPurple.darken4,
    },
  ],
  statusGroups: [
    {
      text: "Leads",
      desc: "Statuses: Cold/Hot/On Hold - Leads",
      icon: "mdi-forum",
      value: "leads",
      actionList: true,
      statuses: ["lead_cold", "lead_hot", "lead_onHold"],
    },
    {
      text: "Samples",
      desc: "Status: Samples - Sent/Received",
      icon: "mdi-folder-question-outline",
      actionList: true,
      statuses: ["samples_sent", "samples_received"],
      value: "samples",
    },
    {
      text: "Active Accounts",
      desc: "Status: Active - Current Payments/Orders Under Hub",
      icon: "mdi-folder-account",
      statuses: ["active_current", "active_ordersUnderHubAccount"],
      actionList: true,
      value: "active",
    },
    {
      text: "Accounts with growing balances",
      desc: "Status: Active - Delinquent payments & Payment Plan - Active",
      icon: "mdi-folder-account",
      statuses: ["active_delinquent", "paymentPlan_active"],
      actionList: false,
      value: "monitor",
    },
    {
      text: "In Collections",
      desc: "Statuses: Payment Plan - No Orders/Credit Hold",
      icon: "mdi-hand-coin",
      statuses: ["paymentPlan_inactive", "credit_hold"],
      actionList: false,
      value: "collections",
    },
    {
      text: "Inactive/Out of Business",
      desc: "Statuses: Out of Business - Was/Was not a client + DO NOT CALL",
      icon: "mdi-storefront-remove",
      statuses: ["oob_client", "oob_notclient", "do_not_call"],
      actionList: false,
      value: "inactive",
    },
  ],
  activityTypes: [
    {
      icon: "list-status",
      text: "Status",
      value: "status",
      canSelect: false,
      canComplete: false,
      notesRequired: false,
      endDate: false,
    },
    {
      icon: "account-group",
      text: "Meeting",
      value: "meeting",
      canSelect: true,
      canComplete: true,
      notesRequired: true,
      endDate: true,
    },
    {
      icon: "phone",
      text: "Call",
      value: "call",
      canSelect: true,
      canComplete: true,
      notesRequired: true,
      endDate: true,
    },
    {
      icon: "voicemail",
      text: "LVM",
      value: "lvm",
      canSelect: true,
      canComplete: false,
      notesRequired: true,
      endDate: false,
    },
    {
      icon: "message-processing",
      text: "Text Message",
      value: "text_message",
      canSelect: true,
      canComplete: true,
      notesRequired: true,
      endDate: false,
    },
    {
      icon: "email-send",
      text: "Email",
      value: "email",
      canSelect: true,
      canComplete: true,
      notesRequired: true,
      endDate: true,
    },
    {
      icon: "wifi",
      text: "Social Media Message",
      value: "social_media_message",
      canSelect: true,
      canComplete: true,
      notesRequired: true,
      endDate: false,
    },
    {
      icon: "police-badge",
      text: "Accounts Receiveable",
      value: "ar",
      canSelect: true,
      canComplete: false,
      notesRequired: true,
      endDate: false,
    },
    {
      icon: "flag-triangle",
      text: "Deadline",
      value: "deadline",
      canSelect: true,
      canComplete: true,
      notesRequired: true,
      endDate: true,
    },
    {
      icon: "calendar-check",
      text: "Task",
      value: "task",
      canSelect: true,
      canComplete: true,
      notesRequired: true,
      endDate: true,
    },
    {
      icon: "order-bool-ascending-variant",
      text: "Order",
      value: "order",
      canComplete: false,
      notesRequired: true,
      endDate: false,
    },
  ],
  storeTypes: [
    { text: "Storefront", value: "dispensary" },
    { text: "Delivery", value: "delivery" },
    { text: "Distro", value: "distro" },
    { text: "Smoke Shop", value: "smokeShop" },
    { text: "NONE", value: "", active: false, canOrder: false },
  ],
  shelvingFeeTypes: [
    {
      text: "Flat Fee",
      desc: "Constant $ amount per month",
      value: "flat",
    },
    {
      text: "Monthly Percentage",
      desc: "Percentage of sales per month",
      value: "percentage",
    },
    {
      text: "Both",
      desc: "Constant $ amount / Monthly %",
      value: "both",
    },
    {
      text: "[Not Set]",
      desc: "",
      value: "",
    },
  ],
  orderAllocation: [
    {
      text: "Each account orders individually (1:1)",
      desc: "Each account orders individually (each account has their own orders/balance)",
      value: "oneToOne",
    },
    {
      text: "One order for multiple accounts (1:many)",
      desc: "One 'distro' orders for multiple (2+) accounts",
      value: "oneToMany",
    },
  ],
  accountSource: [
    {
      text: "[NOT SET]",
      desc: "-",
      value: null,
    },
    {
      text: "Self Generated",
      desc: "Lead was generated through prospecting",
      value: "selfGenerated",
    },
    {
      text: "Company Generated",
      desc: "Lead generated through social media/online/form submission",
      value: "companyGenerated",
    },
    // {
    //   text: "Online",
    //   desc: "Found online through a search engine/menu listing/form submission on company website/etc",
    //   value: "online",
    // },
    {
      text: "External Referral",
      desc: "Lead was generated from someone outside of the company/brand",
      value: "referral",
    },
    {
      text: "Event/Trade show",
      desc: "Lead was generated from an event/trade show",
      value: "eventTradeShow",
    },
  ],
  // Budtender Trainings
  budtenderTrainingStatus: [
    { text: "Cancelled", value: "cancelled", color: "red" },
    { text: "In-Progress", value: "in progress", color: "black" },
    { text: "Complete", value: "complete", color: "green" },
  ],
  budtenderTrainingTypes: [
    { text: "Team", value: "team" },
    { text: "Individual", value: "individual" },
  ],
  budtenderTrainingFormat: [
    { text: "In Person", value: "inPerson" },
    { text: "Online", value: "online" },
  ],
  estimatedBudtenders: [
    { text: "1-5", value: "5", meal: 50, swag: 5, sample: 5 },
    { text: "5-10", value: "10", meal: 75, swag: 10, sample: 10 },
    { text: "10-15", value: "15", meal: 100, swag: 15, sample: 15 },
    { text: "15-20", value: "20", meal: 150, swag: 20, sample: 20 },
    { text: "21+", value: "21", meal: 200, swag: "21+", sample: "21+" },
  ],
  budtenderTrainingProvidingOptions: [
    { text: "Pending", value: "pending" },
    { text: "Yes", value: "yes" },
    { text: "No", value: "no" },
  ],
};

const actions = {
  _activityDialog({ commit }, payload) {
    commit("setActivityDialogState", payload);
  },
};

const mutations = {
  setActivityDialogState(state, payload) {
    state.activityDialog = payload;
  },
};

const getters = {
  getAccountStatus(state) {
    return state.accountStatus;
  },
  getAccountSubstatus(state) {
    return state.subStatus;
  },
  getAccountStatusGroups(state) {
    return state.statusGroups;
  },
  getStoreTypes(state) {
    return state.storeTypes;
  },
  getActivityTypes() {
    return state.activityTypes;
  },
  getActivityDialog(state) {
    return state.activityDialog;
  },
  getShelvingFeeTypes(state) {
    return state.shelvingFeeTypes;
  },
  getOrderAllocation(state) {
    return state.orderAllocation;
  },
  getAccountSource(state) {
    return state.accountSource;
  },
  // Budtender Trainings
  getBudtenderTrainingStatus() {
    return state.budtenderTrainingStatus;
  },
  getBudtenderTrainingTypes() {
    return state.budtenderTrainingTypes;
  },
  getEstimatedBudtenderAmounts() {
    return state.estimatedBudtenders;
  },
  getBudtenderProvidingOptions() {
    return state.budtenderTrainingProvidingOptions;
  },
  getBudtenderTrainingFormats() {
    return state.budtenderTrainingFormat;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
